<template>
  <base-map
    :name="name"
    :center="mapCenter"
    :zoom="mapZoom"
    @click="onMapClick"
    @update:zoom="updateMapZoom"
    :anchorable="anchorable"
  >
    <l-polyline
      v-for="sentry in sentries"
      :key="`sentry-${sentry.id}-${markerRenderKey}-location-history`"
      :v-if="sentry.tracked"
      :opacity="1"
      color="rgb(97, 186, 101)"
      :weight="3"
      dash-array="4, 10"
      :fill-opacity="0"
      :lat-lngs="sentry.location_history.map(i => [i.latitude, i.longitude])"
    />
    <radar-sector-coverage
      v-for="sensor in radars"
      :visible="
        visibleSectorType(activeSiteId, 'radar') ||
          isActiveSentryId('radars', sensor) ||
          inActiveSentries('radars', sensor) ||
          showCoverageSector(sensor, 'radars')
      "
      :active="isSensorActive(sensor, 'radars')"
      :key="`radar-sector-${sensor.id}`"
      :sensor="sensor"
    />
    <rf-sector-coverage
      :visible="
        visibleSectorType(
          activeSiteId,
          sensor.model === 'rf_zero'
            ? 'rf_zero'
            : sensor.model === 'rf_patrol'
            ? 'rf_patrol'
            : 'rf_one'
        ) ||
          isActiveSentryId('rf_sensors', sensor) ||
          inActiveSentries('rf_sensors', sensor) ||
          showCoverageSector(sensor, 'rf_sensors')
      "
      :active="isSensorActive(sensor, 'rf_sensors')"
      v-for="sensor in rfsensors"
      :key="`rf-sector-${sensor.id}`"
      :sensor="sensor"
    />

    <dronesentryx-sector-coverage
      v-for="sensor in dsxsensors"
      :visible="showDsxCoverageSector(sensor)"
      :active="isSensorActive(sensor, 'dsx_sensors')"
      :jamming="sensor.jamming"
      :showJammingSectorOnly="
        highlightedCannon === sensor.id || highlightedAllCannons
      "
      :key="`dsx-sector-${sensor.id}`"
      :sensor="sensor"
    />
    <discovair-sector-coverage
      :visible="
        visibleSectorType(activeSiteId, 'discovair') ||
          isActiveSentryId('discovair_sensors', sensor) ||
          showCoverageSector(sensor, 'discovair_sensors')
      "
      :active="isSensorActive(sensor, 'discovair_sensors')"
      v-for="sensor in discovairsensors"
      :key="`discovair-sector-${sensor.id}`"
      :sensor="sensor"
    />
    <intersection-coverage
      v-if="visibleSectorType(activeSiteId, 'rf_intersection')"
      :visible="true"
      :sensors="rfSensorsInSite(activeSiteId, sentries)"
    />
    <cannon-sector-coverage
      :visible="showCannonSector(cannon)"
      :active="isSensorActive(cannon, 'cannons')"
      :highlighted="highlightedCannon === cannon.id || highlightedAllCannons"
      v-for="cannon in cannons"
      :key="`cannon-sector-${cannon.id}`"
      :sensor="cannon"
    />
    <camera-sector-coverage
      :visible="showCoverageSector(camera, 'cameras')"
      :active="activeCamera && activeCamera.id === camera.id"
      v-for="camera in cameras"
      :key="`camera-sector-${camera.id}`"
      :sensor="camera"
    />
    <rf-detection-sector
      :visible="true"
      @selectBeam="onSelectRfBeam"
      v-for="item in detections.rf"
      v-bind="item"
      :active-beam="activeBeam"
      :active-rf-id="activeRfSensorId"
      :key="`rfbeam-${item.id}`"
    />
    <dronesentryx-detection-sector
      :visible="true"
      @selectBeam="onSelectRfBeam"
      v-for="item in detections.dsx"
      v-bind="item"
      :active-beam="activeBeam"
      :active-rf-id="activeRfSensorId"
      :key="`dsxbeam-${item.id}`"
    />
    <discovair-detection-sector
      :visible="true"
      @selectBeam="onSelectRfBeam"
      v-for="item in detections.discovair"
      v-bind="item"
      :active-beam="activeBeam"
      :active-sensor-id="activeDiscovair_sensorId"
      :key="`discovairbeam-${item.id}`"
    />
    <camera-detection-layer
      :visible="true"
      v-for="detection in filteredCameraDetections"
      :key="`detection-${detection.camera_id}-${detection.target_id}`"
      :detection="detection"
      :active-key="activeKey"
      :cameraRfTrack="isCameraDetectingTrack.rf"
      @selectDetection="onSelectRadarDetection"
    />
    <detection-layer
      :visible="true"
      v-for="detection in detections.radars"
      :key="`detection-${detection.target_id}`"
      :detection="detection"
      :active-key="activeKey"
      :isCameraDetectingRadar="isCameraDetectingTrack.radar"
      @selectDetection="onSelectRadarDetection"
    />

    <map-site-markers-layer
      :visible="true"
      v-for="marker in site_markers"
      :key="`site_marker-${marker.id}`"
      :marker="marker"
      :active-key="activeKey"
    />

    <intersection-detection
      v-for="(d, i) in detections.rfIntersections"
      :key="`rfi-${i}`"
      :detection="d"
      :active-key="activeKey"
      :origin="getOrigin(d.correlation_key)"
      :detection-number="d.markerNumber"
      :isCameraDetectingRF="isCameraDetectingTrack.rf"
      @selectDetection="onSelectRadarDetection"
    />
    <real-drone-detection-layer
      v-for="(d, i) in detections.realDroneDetections"
      :key="`real-drone-${i}`"
      :detection="d"
      :active-key="activeKey"
      :origin="getOrigin(d.drone_id)"
      @selectDetection="onSelectRadarDetection"
    />
    <simulation-detection-layer
      v-for="(d, i) in detections.simulationDetections"
      :key="`simulation-${i}`"
      :detection="d"
      :active-key="activeKey"
      @selectDetection="onSelectRadarDetection"
    />
    <l-feature-group
      v-for="sentry in sentries"
      :key="`sentry-${sentry.id}-${markerRenderKey}`"
    >
      <compass-marker
        v-if="locator"
        :compass="locator"
        :sentry="sentry"
        :sentryEditMode="true"
      />
      <!-- compass marker for mobile installation direction -->
      <compass-marker
        v-if="
          sentry.sentry_type !== 'fixed' && activeSentries.includes(sentry.id)
        "
        :compass="sentry"
        :sentry="sentry"
      />

      <sentry-marker
        :active="activeSentries.includes(sentry.id)"
        :icon-color="$_getStatusColor(sentry)"
        :draggable="editMode && !hasLocator(sentry)"
        :sentry="sentry"
        :active-sentry-id="activeSentryId"
        :camera-detections="detections.camera"
        @selectSentry="selectSentry"
        @update:latLng="$emit('update:latLng', $event)"
        :sensors="sensors"
      />
    </l-feature-group>

    <component
      v-for="(z, i) in zonesList"
      :is="`l-polygon-${z.zone_type === 'label' ? 'tooltip' : 'fill-pattern'}`"
      :visible="visibleZoneType.includes(z.zone_type)"
      :key="`z-${i}`"
      :ref="`z-${z.id}`"
      :lat-lngs="z.coordinate_list"
      :color="zoneTypeColors[z.zone_type].strokeColor"
      :fill-color="zoneTypeColors[z.zone_type].fillColor"
      :fill-opacity="zoneTypeColors[z.zone_type].fillOpacity"
      :fill-pattern="zoneTypeColors[z.zone_type].fillPattern"
      :tooltip="z.name"
      :imageScale="0.6"
      :weight="zoneTypeColors[z.zone_type].strokeWeight"
      :opacity="zoneTypeColors[z.zone_type].strokeOpacity"
    />

    <site-marker :center="siteCenter" />

    <radiating-circles />

    <l-feature-group v-if="showFusionDetectionOnMap" data-testid="fusion-detection-feature">
      <FusionDetection
        v-for="detection in fusionDetectionItems"
        :detection="detection"
        class="fusion-detection"
        :key="`fusion-detection_${detection.target_id}`"
        @selectSfRfBeam="onSelectSfRfBeam"
      />
    </l-feature-group>
  </base-map>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import {
  LControl,
  LFeatureGroup,
  LPolyline,
  LMarker,
  LPopup,
  LIcon
} from 'vue2-leaflet'
import PolygonTooltip from 'vue2-leaflet-polygontooltip'

import BaseMap from './BaseMap'

const RfDetectionSector = () => import('./Detection/RfDetectionSector')
const DiscovairDetectionSector = () =>
  import('./Detection/DiscovairDetectionSector')
const DronesentryxDetectionSector = () =>
  import('./Detection/DronesentryxDetectionSector')
const DetectionLayer = () => import('./Detection/DetectionLayer')
const CameraDetectionLayer = () => import('./Detection/CameraDetectionLayer')
const IntersectionDetection = () => import('./Detection/Intersection')
const RealDroneDetectionLayer = () =>
  import('./Detection/RealDroneDetectionLayer')
const SimulationDetectionLayer = () =>
  import('./Detection/SimulationDetectionLayer')

const SentryMarker = () => import('./Marker/Sentry')
const SiteMarker = () => import('./Marker/Site')
const CompassMarker = () => import('./Marker/Compass')
const RadiatingCircles = () => import('./Element/RadiatingCircles')
const CameraSectorCoverage = () => import('./Coverage/CameraSector')
const CannonSectorCoverage = () => import('./Coverage/CannonSector')
const DiscovairSectorCoverage = () => import('./Coverage/DiscovairSector')
const DronesentryxSectorCoverage = () => import('./Coverage/DronesentryxSector')
const RadarSectorCoverage = () => import('./Coverage/RadarSector')
const RfSectorCoverage = () => import('./Coverage/RFSector')
const IntersectionCoverage = () => import('./Coverage/Intersection')
const MapSiteMarkersLayer = () => import('./MapSiteMarkers/MapSiteMarkersLayer')

import { SentryMixin } from '@/components/Mixins'

const FusionDetection = () =>
  import('./Detection/SensorFusion/FusionDetection.vue')

//import components

Vue.use(PolygonTooltip)

const props = {
  detections: {
    type: Object,
    default: () => ({
      radars: {},
      rf: {},
      dsx: {},
      rfIntersections: {},
      discovair: {}
    })
  },
  editMode: {
    type: Boolean,
    default: false
  },
  center: {
    type: Array,
    default: () => []
  },
  zoom: {
    type: Number,
    default: 16
  },
  anchorable: {
    type: Boolean,
    default: false
  },
  isCameraDetectingTrack: {
    type: Object,
    default: () => {}
  }
}

export default {
  name: 'TheMap',
  mixins: [SentryMixin],
  components: {
    FusionDetection,
    MapSiteMarkersLayer,
    BaseMap,
    RfSectorCoverage,
    RadarSectorCoverage,
    CannonSectorCoverage,
    CameraSectorCoverage,
    DiscovairSectorCoverage,
    RfDetectionSector,
    DronesentryxDetectionSector,
    DiscovairDetectionSector,
    CompassMarker,
    SentryMarker,
    SiteMarker,
    DetectionLayer,
    CameraDetectionLayer,
    IntersectionDetection,
    IntersectionCoverage,
    RadiatingCircles,
    DronesentryxSectorCoverage,
    LControl,
    LPolyline,
    LFeatureGroup,
    RealDroneDetectionLayer,
    LMarker,
    LPopup,
    SimulationDetectionLayer
  },
  props,
  data: () => ({
    map: null,
    loading: false,
    clickedBeam: false,
    name: 'homeMap',
    highlightedZone: null,
    markerRenderKey: 0,
    staleSiteCenter: [0, 0]
  }),
  methods: {
    ...mapActions('sentries', { selectSentry: 'SELECT_SENTRY' }),
    ...mapActions('sensors', [
      'selectDetection',
      'selectRFBeam'
    ]),
    ...mapActions('rf_sensors', ['SELECT_RF_SENSOR']),
    ...mapActions('maps', ['setMapZoom']),
    ...mapActions('selection', [
      'clearSentries',
      'clearSensors',
      'clearGroups',
      'activateSentry',
      'activateGroup',
      'activateSensor',
      'setActiveCamera'
    ]),
    ...mapActions('detection', ['clearSelectedDetections']),
    ...mapActions('detection', { selectSfRfBeam: 'selectRFBeam' }),
    updateMapZoom(zoom) {
      const {siteZoom} = this;
      this.setMapZoom({zoom, siteZoom})
    },
    isSensorActive(sensor, type) {
      const device = this.activeSensors.find(s => {
        return s.sensor === sensor.id && s.type === type
      })
      return !!device
    },

    invalidateMap() {
      this.$refs.map && this.$refs.map.invalidateSize()
    },
    isActiveSentryId(type, sensor) {
      if (Object.keys(this.activeGroup).includes(type)) {
        return (
          !!this.activeGroup[type] && sensor.sentry_id === this.activeSentryId
        )
      } else {
        return false
      }
    },
    showCannonSector(cannon) {
      return (
        this.visibleSectorType(this.activeSiteId, 'cannon') ||
        this.isActiveSentryId('cannons', cannon) ||
        cannon.power_trigger_engaged === true ||
        this.highlightedCannon === cannon.id ||
        this.highlightedAllCannons ||
        this.showCoverageSector(cannon, 'cannons')
      )
    },
    showDsxCoverageSector(sensor) {
      return (
        this.visibleSectorType(this.activeSiteId, 'dronesentryx') ||
        this.isActiveSentryId('dsx_sensors', sensor) ||
        this.showCoverageSector(sensor, 'dsx_sensors') ||
        this.highlightedCannon === sensor.id ||
        this.highlightedAllCannons ||
        sensor.jamming
      )
    },
    inActiveSentries(type, sensor) {
      if (Object.keys(this.activeGroup).includes(type)) {
        return (
          !!this.activeGroup[type] &&
          this.activeSentries.includes(sensor.sentry_id)
        )
      } else {
        return false
      }
    },
    hasLocator(sentry) {
      return sentry && sentry.locator && sentry.locator['id']
    },
    getIntersectionIndex(key) {
      return Object.keys(this.rfIntersectionsArray).indexOf(key)
    },
    onSelectRadarDetection(key) {
      this.selectDetection(key)
      this.selectRFBeam(null)
    },
    onSelectRfBeam(key) {
      // const key = item ? `${item.correlation_key}_${item.rf_sensor_id}` : null;
      this.selectRFBeam(key)
      this.clickedBeam = true
      this.selectDetection(null)
    },
    onSelectSfRfBeam() {
      this.clickedBeam = true
      this.selectDetection(null)
    },
    async onMapClick(_, e) {
      if (this.clickedBeam) {
        return (this.clickedBeam = false)
      } else {
        if (this.activeBeam !== null) {
          this.selectRFBeam(null)
        }
        if (this.sfActiveBeam !== null) {
          this.selectSfRfBeam(null)
        }
        if (this.activeKey !== null) {
          this.selectDetection(null)
        }
        if (!this.editMode) this.selectSentry(null)

        this.clearSentries()

        if (this.selectedDetections.length > 0
          && !e.originalEvent.target.classList.contains('leaflet-marker-pane')) {
          this.clearSelectedDetections()
        }
      }
    },
    visibleSectorType(activeSiteId, type) {
      return Object.values(this.displayedSectorsInSite(activeSiteId)).some(
        v => v.value === type
      )
    },
    showCoverageSector(sensor, type) {
      let status =
        !!this.activeGroups.find(item => {
          return item.sentry === sensor.sentry_id && item.group === type
        }) && this.activeSentries.includes(sensor.sentry_id)
      if (type === 'cameras' && this.activeCamera) {
        status = status && sensor.id === this.activeCamera.id
      }
      return status || this.visibleSectorType(this.activeSiteId, type)
    }
  },
  computed: {
    ...mapState('sentries', ['activeSentries']),
    ...mapState('sensors', ['activeKey', 'activeBeam', 'origins']),
    ...mapState('detection', { sfActiveBeam: 'activeRfBeam' }),
    ...mapState('zones', ['visibleZoneType', 'zoneTypeColors']),
    ...mapState('site_markers', ['site_markers']),
    ...mapGetters('zones', ['zonesList']),
    ...mapGetters('users', ['isAuthorized']),
    ...mapGetters('sites', [
      'activeSiteId',
      'activeSite',
      'siteZoom',
      'siteCenter',
      'isCustomMapCenter',
      'mapCenter'
    ]),
    ...mapState(['activeGroup']),
    ...mapGetters(['displayedSectorsInSite']),
    ...mapGetters('sensors', [
      'allCannons',
      'radarsSet',
      'radarsInSite',
      'discovair_sensorsInSite',
      'cannonsInSite',
      'getOrigin',
      'activeDiscovair_sensor',
      'activeRadar',
      'activeCannon',
      'activeGps_compass',
      'activeDiscovair_sensorId',
      'highlightedCannon',
      'highlightedAllCannons'
    ]),
    ...mapGetters('rf_sensors', [
      'rfSensorsInSite',
      'dsxSensorsInSite',
      'activeRfSensorId'
    ]),
    ...mapGetters('cameras', ['cameraTrackedTarget', 'camerasInSite']),
    ...mapGetters('maps', ['mapZoom']),
    ...mapState('selection', {
      activeSentries: 'activeSentries',
      activeSensors: 'activeSensors',
      activeGroups: 'activeGroups',
      activeCamera: 'activeCamera'
    }),
    ...mapState('detection', ['fusionDetections', 'selectedDetections']),
    showFusionDetectionOnMap() {
      return this.$route.path === '/'
    },
    mapMeasurementUnit() {
      return this.systemSetting.display_unit
    },
    fusionDetectionItems() {
      return Object.values(this.fusionDetections)
    },
    mapMarkersIcon() {
      return L.icon({
        iconUrl: require(`@/assets/marker_blank.svg`),
        iconSize: [40, 40],
        iconAnchor: [20, 40]
      })
    },
    activeDevices() {
      const active = {}
      this.activeSensors.forEach(item => {
        active[item.sensor] = true
      })
      return active
    },
    sentries() {
      return this.sentriesInSite(this.activeSiteId)
    },
    sentryIds() {
      return this.sentries && this.sentries.map(s => s.id)
    },
    ...mapGetters('sentries', [
      'activeSentry',
      'activeSentryId',
      'sentriesInSite'
    ]),
    sensors() {
      return {
        radars: Object.values(this.radars),
        rfs: Object.values(this.rfsensors),
        cannons: Object.values(this.cannons),
        acoustics: Object.values(this.discovairsensors),
        cameras: Object.values(this.cameras),
        dsx: Object.values(this.dsxsensors)
      }
    },
    radars() {
      return this.radarsInSite(this.activeSiteId, this.sentries)
    },
    cannons() {
      const cannons = this.cannonsInSite(this.activeSiteId, this.sentries)
      return Object.values(cannons).filter(cannon => {
        return cannon.rf_sensor_id == null
      })
    },
    cameras() {
      return this.camerasInSite(this.activeSiteId, this.sentries)
    },
    rfsensors() {
      return this.rfSensorsInSite(this.activeSiteId, this.sentries)
    },
    dsxsensors() {
      return this.dsxSensorsInSite(this.activeSiteId, this.sentries)
    },
    discovairsensors() {
      return this.discovair_sensorsInSite(this.activeSiteId, this.sentries)
    },
    active() {
      return (
        (this.activeGroup && this.activeGroup['cannons']) ||
        this.activeGroup['rf_sensors'] ||
        this.activeGroup['radars'] ||
        this.activeGroup['gps_compasses']
      )
    },
    locator() {
      return this.editMode ? this.activeSentry : this.activeGps_compass
    },
    isCameraRadarTracking() {
      // radar currently detecting what camera tracks
      return this.detections.radars.hasOwnProperty(this.cameraTrackedTarget)
    },
    isCameraRfIntersectionTracking() {
      // rf intersection currently detecting what camera tracks
      return this.detections.rfIntersections.hasOwnProperty(
        this.cameraTrackedTarget
      )
    },
    isCameraRfTracking() {
      // rf currently detecting what camera tracks
      return Object.keys(this.detections.rf).some(
        k => k.split(':')[0] === this.cameraTrackedTarget
      )
    },
    filteredCameraDetections() {
      return this.isCameraDetectingTrack?.radar ||
        this.isCameraDetectingTrack?.rfInt
        ? {}
        : Object.fromEntries(
            Object.entries(this.detections?.camera || {}).filter(
              ([_, v]) => v.active_track
            )
          )
    }
  },
  beforeDestroy() {
    this.$bus.$off('highlightZone')
    this.$bus.$off('cancelSentry')
  },
  beforeMount() {
    this.staleSiteCenter = this.siteCenter
  },
  mounted() {
    this.$bus.$on('highlightZone', id => {
      if (!id) return
      if (!this.$refs[`z-${id}`][0]) {
        console.warn('zone path does not exist')
        return
      } // this will avoid an error but it won't highlight the zone

      const zone = this.$refs[`z-${id}`][0].mapObject._path
      zone.classList.add('blinkMap')
      setTimeout(() => {
        zone.classList.remove('blinkMap')
        this.highlightedZone = null
      }, 10000)
    })
    this.$bus.$on('cancelSentry', () => {
      this.markerRenderKey++ //re-render marker to reset location
    })
  },
  watch: {
    cameraTrackedTarget(v) {
      if (!v) return
      const camera = this.sensors?.cameras?.find(
        c => c.radar_tracked_target == v || c.rf_tracked_target == v
      )
      if (camera) this.setActiveCamera(camera)
    },
    async activeSiteId(v) {
      this.staleSiteCenter = this.siteCenter
    }
  }
}
</script>

<style scoped>
>>> .blinkMap {
  -webkit-animation: blink 1.5s infinite;
  animation: blink 1.5s infinite;
}

@-webkit-keyframes blink {
  0% {
    stroke-width: 3;
    stroke-opacity: 0.5;
    fill-opacity: 0.2;
  }
  50% {
    stroke-width: 5;
    stroke-opacity: 1;
    fill-opacity: 0.3;
  }
  100% {
    stroke-width: 3;
    stroke-opacity: 0.5;
    fill-opacity: 0.2;
  }
}

@keyframes blink {
  0% {
    stroke-width: 3;
    stroke-opacity: 0.5;
    fill-opacity: 0.2;
  }
  50% {
    stroke-width: 5;
    stroke-opacity: 1;
    fill-opacity: 0.3;
  }
  100% {
    stroke-width: 3;
    stroke-opacity: 0.5;
    fill-opacity: 0.2;
  }
}

.leaflet-tooltip {
  position: relative;
  background-color: rgba(0, 0, 0, 0.8);
  border: none;
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  color: orange;
}

.leaflet-tooltip-right {
  left: 10px;
}

.leaflet-tooltip-left {
  right: 5px;
}

.leaflet-tooltip-left:before {
  border-left-color: rgba(0, 0, 0, 0.8);
}

.leaflet-tooltip-right:before {
  border-right-color: rgba(0, 0, 0, 0.8);
}
</style>
